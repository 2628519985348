import { RadioButton } from "primereact/radiobutton";
import { Controller } from "react-hook-form";

// Types
import { ICommonProps } from "../types";

export function OwnsAnotherProperty({control,formState,setValue,watch}: ICommonProps<any, any, any,any>) {

    // AUX Variables
    const INPUT_owns_another_property_WATCH = watch!('owns_another_property')

    return (<span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-2 border-b-[1px] border-gray-300">
            <label htmlFor="">Possui outro imóvel ?</label>
            <span data-haserrors={formState?.errors?.owns_another_property?.message && true}
                className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.owns_another_property?.message}</span>
            <Controller
                control={control}
                name="owns_another_property"
                render={({
                    field,
                }) => (
                    <>
                        <span className="flex flex-row gap-2 items-center mb-4">
                            <label htmlFor={field.name + '#1'} className="text-sm font-semibold order-2">Sim</label>
                            <RadioButton
                                name={field.name}
                                inputId={field.name + '#1'}
                                value={true}
                                defaultChecked={true}
                                checked={INPUT_owns_another_property_WATCH === true }
                                onChange={d => setValue('owns_another_property', d.value)}
                            />
                        </span>
                        <span className="flex flex-row gap-2 items-center mb-4">
                            <label htmlFor={field.name + '#2'} className="text-sm font-semibold order-2">Não</label>
                            <RadioButton
                                name={field.name}
                                inputId={field.name + '#2'}
                                value={false}
                                checked={INPUT_owns_another_property_WATCH === false}
                                onChange={d => setValue('owns_another_property', d.value)}
                            />
                        </span>
                    </>
                )}
            />
        </span>
    )
}