import React, { useCallback, useEffect, useState } from 'react';

import { useServiceOrder } from '~/hooks/ServiceOrder';
import AmortizationSimulation from './Components/AmortizationSimulation';
import BudgetAmount from './Components/BudgetAmount';
import DownloadDocumentsBox from './Components/DownloadDocumentsBox';
import PerformanceComparator from './Components/PerformanceComparator';
import PersonalInformationBox from './Components/PersonalInformationBox';
import ProjeteiSuggestion from './Components/ProjeteiSuggestion';
import PropertyEnvironments from './Components/PropertyEnvironments';
import PropertyValuation from './Components/PropertyValuation';
import ResourceComposition from './Components/ResourceComposition';
import SimulationConditions from './Components/SimulationConditions';
import AguardandoAnalise from './Components/Warnings/AguardandoAnalise';

import AguardandoAssinatura from './Components/Warnings/AguardandoAssinatura';
import AlgoErrado from './Components/Warnings/AlgoErrado';
import AutorizacaoEngenharia from './Components/Warnings/AutorizacaoEngenharia';
import AutorizacaoEnviada from './Components/Warnings/AutorizacaoEnviada';
import CompleteData from './Components/Warnings/CompleteData';
import ContratoPronto from './Components/Warnings/ContratoPronto';
import EmAnalise from './Components/Warnings/EmAnalise';
import InformarAssinatura from './Components/Warnings/InformarAssinatura';
import LaudoAprovado from './Components/Warnings/LaudoAprovado';
import Parabens from './Components/Warnings/Parabens';

import { Link } from 'react-router-dom';
import { SCFormAC, SCFormCT } from '~/pages/ServiceOrderComplementary/Partials/Form/schemas';
import api from '~/services/api';
import AguardandoLiberacao from './Components/Warnings/AguardandoLiberacao';
import CreditoAprovado from './Components/Warnings/CreditoAprovado';
import CreditoReprovado from './Components/Warnings/CreditoReprovado';
import LaudoReprovado from './Components/Warnings/LaudoReprovado';
import { Mock } from './Partials/Mock';
import { ProofPayment } from './Partials/ProofPayment';
import {
  CardBox,
  CardBoxInformationsSub,
  EnvironmentContainer,
} from './styles';

interface IStepMenuDataOs {
  setContentData: React.Dispatch<React.SetStateAction<string>>;
}

const ServiceOrderData: React.FC<IStepMenuDataOs> = ({ setContentData }) => {
  const { serviceOrder } = useServiceOrder();
  const [completaryData, setComplementaryData] = useState<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT> | null>(null)

  // TODO - THIS CALL CAN BE DEPRECATED BECAUSE THE MAIN OS DATA ALREADY HAVE THE COMPLEMENTARY DATA
  const handleGetComplementaryData = useCallback(async () => {
    try {
      if (!serviceOrder || !serviceOrder?.id) throw new Error('handleGetComplementaryData');

      const response = await api.get<Zod.infer<typeof SCFormAC> & Zod.infer<typeof SCFormCT>>(`builders/${serviceOrder?.id}/complementary-data`);

      if (!response || response.status !== 200) throw new Error("Don't have data to show");      

      setComplementaryData(response.data)
    } catch (error) {
      // do anything
      console.table(error)
    }
  }, [serviceOrder])

  useEffect(() => {
    if (serviceOrder.finalidade_id <= 2) {
    }
    handleGetComplementaryData()
  }, [serviceOrder])

  return (
    <>
      <PersonalInformationBox />

      {Object.keys(serviceOrder).length <= 0 ? (
        <Mock />
      ) : (
        <>
          {serviceOrder.status && (
            <>
              {serviceOrder.status.id === 2
                && !serviceOrder?.cliente?.nvEscolar_id && (
                  <div className="row mt-2">
                    <CompleteData setContentData={setContentData} />
                  </div>
              )}

              {serviceOrder.status.id === 4 && (
                <div className="row mt-5 mb-5">
                  <EmAnalise />
                </div>
              )}

              {serviceOrder.status.id === 7 && (
                <div className="row mt-5 mb-5">
                  <CreditoReprovado />
                </div>
              )}

              {serviceOrder.status.id === 8 && (
                <div className="row mt-5 mb-5">
                  <CreditoAprovado />
                </div>
              )}

              {serviceOrder.status.id === 11 && (
                <div className="row mt-2">
                  <AlgoErrado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 13 && (
                <div className="row mt-2">
                  <AutorizacaoEngenharia />
                </div>
              )}

              {serviceOrder.status.id === 14 && (
                <div className="row mt-2">
                  <AutorizacaoEnviada />
                </div>
              )}

              {serviceOrder.status.id === 15 && (
                <div className="row mt-2">
                  <LaudoReprovado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 17 && (
                <div className="row mt-2">
                  <LaudoAprovado setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 18 && (
                <div className="row mt-2">
                  <AguardandoAssinatura setContentData={setContentData} />
                </div>
              )}

              {serviceOrder.status.id === 19 && (
                <div className="row mt-2">
                  <AguardandoAnalise />
                </div>
              )}

              {serviceOrder.status.id === 21 && (
                <div className="row mt-2">
                  <AguardandoLiberacao />
                </div>
              )}


              {serviceOrder.status.id === 22 && (
                <div className="row mt-2">
                  <ContratoPronto />
                </div>
              )}

              {serviceOrder.status.id === 23 && (
                <div className="row mt-2">
                  <Parabens />
                </div>
              )}
              {serviceOrder.status.id === 25 && (
                <div className="row mt-2">
                  <InformarAssinatura />
                </div>
              )}
            </>
          )}
          
          {serviceOrder.cliente.casado && (!serviceOrder?.conjuge || !serviceOrder?.conjuge?.id) && (
            <span className='w-full h-auto p-8 flex flex-col gap-8 rounded-xl bg-[#fff2ab] my-8 border-[1px] border-[#ffe762]'>
              <hgroup>
                <h2 className='font-semibold text-lg'>Proponente</h2>
                <h3 className='font-normal text-md mt-2'>
                  A opção <strong>Documentos da Obra</strong> só vai ser liberada quando seu/sua cônjuge por cadastrado(a)
                </h3>
              </hgroup>
            </span>
          )}

          {(!completaryData && serviceOrder.finalidade_id <= 2 && (serviceOrder.status_id <= 3 || serviceOrder.status_id === 5)) && (
            <span className='w-full h-auto p-8 flex flex-col gap-8 rounded-xl bg-[#fff2ab] my-8 border-[1px] border-[#ffe762]'>
              <hgroup>
                <h2 className='font-semibold text-lg'>Dados complementares</h2>
                <h3 className='font-normal text-md mt-2'>
                 Po algum motivo interno não foi possivel registrar os dados complementares
                </h3>
              </hgroup>

              <Link 
                to={`../simulador/dados-complementar/${serviceOrder?.id || -1}`}
                className=' 
                  w-full
                  md:w-1/2
                  h-12
                  flex flex-row 
                  flex-nowrap items-center 
                  justify-center gap-3 
                  rounded-[25px]
                  bg-[#39a887]
                  hover:bg-[#2c8168]
                  hover:active:bg-[#226450]
                  active:bg-[#2c8168]
                  active:scale-105
                  transition-all
                  duration-[0.37s]
                  text-white
                '
              >
                Preencher
              </Link>
            </span>
          )}

          {completaryData?.approval_property === 0 && serviceOrder.finalidade_id <= 2 && serviceOrder.status.id <= 5 && (
            <span className='w-full h-auto p-8 flex flex-col gap-8 rounded-xl bg-gray-100 my-8 border-[1px] border-gray-200'>
              <hgroup>
                <h2 className='font-semibold text-lg'>Análise e defesa de proposta de crédito</h2>
                <h3 className='font-normal text-md mt-2'>O link para pagamento está disponivel, clique no botão abaixo</h3>
              </hgroup>
              <a
                href="https://pay.kiwify.com.br/vl7tLf5" 
                target="__blank" 
                className=' 
                  w-full
                  md:w-1/2
                  h-12
                  flex flex-row 
                  flex-nowrap items-center 
                  justify-center gap-3 
                  rounded-[25px]
                  bg-[#39a887]
                  hover:bg-[#2c8168]
                  hover:active:bg-[#226450]
                  active:bg-[#2c8168]
                  active:scale-105
                  transition-all
                  duration-[0.37s]
                  text-white
                '
              >
                Pagar
              </a>
            </span>
          )}

          {!completaryData?.has_rgi && (
            <ProofPayment />
          )}

          <DownloadDocumentsBox />
          {serviceOrder.finalidade_id !== 4
            && serviceOrder.finalidade_id !== 5
            && serviceOrder.finalidade_id !== 6 && (
              <>
                <CardBox className="row mt-4 container_dashboard_info align-items-start">
                  <ResourceComposition />
                  <PropertyValuation />
                  <PerformanceComparator />
                </CardBox>
                <CardBoxInformationsSub className="row container_dashboard_info align-items-start">
                  <AmortizationSimulation />
                  <SimulationConditions />
                  <BudgetAmount />
                </CardBoxInformationsSub>
                <EnvironmentContainer>
                  {serviceOrder.finalidade_id !== 4
                    && serviceOrder.finalidade_id !== 5 && (
                      <PropertyEnvironments />
                  )}

                  <ProjeteiSuggestion />
                </EnvironmentContainer>
              </>
          )}
        </>
      )}
    </>
  );
};

export default ServiceOrderData;
