import { format, parseISO } from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import api from '~/services/api';

import avatar from '~/assets/defaults/avatar.png';
import DataTable from '~/components/DataTable';
import { useDatatable } from '~/hooks/Datatable';
import { useAuthContext } from '~/hooks/contexts/Auth';

interface IServiceOrderData {
  id: number;
  finality: string;
  status: string;
  client: {
    nome: string;
  };
  simulation: {
    created_at: string;
    updated_at: string;
    user_update_name: string | null;
  };
  cliente: string;
  status_id: number;
  finality_id: number;
  correspondent_name?: string;
}

interface IServiceOrderResponse {
  data: IServiceOrderData[];
  from: number;
  last_page: number;
  to: number;
  total: number;
  current_page: number;
}

interface IServiceOrder {
  id: number;
  client: string;
  avatar: string;
  modality: string;
  status: string;
  osNumber: string;
  hourDate: string;
  updateDate: string;  
  updateBy: string | null;
  status_id: number;
  finality_id: number;
  correspondent?: string;
}

interface IOsResponse {
  id: number;
  created_at: string;
  construtor?: {
    name: string;
  };
  user_update_name: string | null
  finality: {
    id: number;
    finalidade: string;
    descricao: string;
  };
  status_id: number;
  status?: {
    id: number;
    status: string;
  };
  requests: [];
  simulacao: {
    vlrRenda: number;
    amortizacao?: {
      id?: number;
      title?: string;
      financedValue: string;
      entryValue?: string;
      realEntryValue?: string;
      fgtsAmount?: string;
      rate?: string;
      firstInstallmentDate?: string;
      firstInstallment: string;
      lastInstallmentDate?: string;
      lastInstallment: string;
      maxInstallment?: string;
      highlight?: boolean;
      deadline?: number;
      color?: string;
    };
  };
  cliente: string;
}

interface IRequestResponse {
  id: number;
  status: {
    id: number;
    status: string;
  };
  created_at: string;
  os: IOsResponse;
  answered?: boolean;
  status_id?: number;
}

interface IRequestData {
  data: IRequestResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

interface IProps {
  responsible: number | null;
}

const TableOS: React.FC<IProps> = ({ responsible: responsable }) => {
  const { user, updateUser } = useAuthContext();
  const [ossBuilder, setOssBuilder] = useState<IServiceOrder[]>([]);
  const [loadingOss, setLoadingOss] = useState(false);
  const [, setCantNewsMarketings] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const {
    search, per_page, page, column, order, setTotalRecords
  } = useDatatable();

  useEffect(() => {
    api
      .get<IRequestData>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/marketings/news`
      )
      .then((res) => {
        setCantNewsMarketings(res.data.total);
      });
  }, []);

  const loadOSs = useCallback(async () => {
    setLoadingOss(true);
    try {
      const response = await api.get<IServiceOrderResponse>(
        `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders`,
        {
          params: {
            per_page,
            page,
            column,
            order,
            search,
            responsable,
          },
        }
      );

      const data: IServiceOrder[] = response.data.data.map((serviceOrder) => ({
        id: serviceOrder.id,
        client: serviceOrder.cliente,
        avatar,
        modality: serviceOrder.finality?.split(' - ')[0] || '',
        status: serviceOrder.status,
        osNumber: serviceOrder.id.toString().padStart(5, '0'),
        hourDate: serviceOrder?.simulation
          ? format(
            parseISO(serviceOrder.simulation.created_at),
            'dd/MM/yy - hh:mm'
          )
          : format(new Date(), 'dd/MM/yy - hh:mm'),
        updateDate: serviceOrder?.simulation
          ? format(
            parseISO(serviceOrder.simulation.updated_at),
            'dd/MM/yy - hh:mm'
          )
          : format(new Date(), 'dd/MM/yy - hh:mm'),
        // @ts-ignore
        updateBy: serviceOrder?.user_update_name || 'N/A',
        status_id: serviceOrder.status_id,
        finality_id: serviceOrder.finality_id,
        correspondent: serviceOrder.correspondent_name,
      }));

      setOssBuilder(data);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.table(error)
      if (user?.name) {
        Swal.fire('Opss...', 'Houve uma falha ao carregar suas OS`s!', 'error');
      }
    } finally {
      setLoadingOss(false);
    }
  }, [
    page,
    responsable,
    search,
    user?.name,
    setTotalRecords,
    per_page,
    column,
    order,
  ]);

  useEffect(() => {
    loadOSs();
  }, [loadOSs]);

  const handleDeleteOs = useCallback(
    (os_id: number) => {
      Swal.fire({
        icon: 'warning',
        title: 'Tem certeza que\ndeseja excluir?',
        text: 'Excluindo sua ordem de serviço você apagará ela\u00A0para\u00A0sempre',
        showCloseButton: true,
        confirmButtonText: 'Excluir',
        confirmButtonColor: '#FF1A50',
        iconColor: '#FF1A50',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api
            .delete<number>(
              `${process.env.REACT_APP_PREFIX_ROUTE}/service-orders/${os_id}`
            )
            .then((res) => {
              updateUser({ ...user, qtdOS: res.data });
            });
          loadOSs();
          Toast.fire({
            icon: 'success',
            title: 'Ordem de serviço deletada!',
          });
        }
      });
    },
    [loadOSs, updateUser, user]
  );

  const handleButtonsTable = useCallback(
    (row: IServiceOrder) => (
      <div
        className="d-flex justify-content-center app-drop-down-container"
        ref={dropdownRef}
      >
        {(row.status_id <= 7 || user!.isAdmin) && (
        <button
          type="button"
          className="bg-transparent border-0"
          onClick={() => {
            handleDeleteOs(parseInt(row.osNumber, 10));
            return false;
          }}
        >
          <BsTrashFill size={15} color="#f62a2a" />
        </button>
        )}
      </div>
    ),
    [handleDeleteOs, user]
  );

  const columns = useMemo<ColumnProps[]>(() => [
    {
      field: 'index',
      header: '#',
    },
    {
      field: 'osNumber',
      header: 'OS',
      sortable: true,
    },
    {
      field: 'client',
      header: 'Cliente',
      sortable: true,
    },
    {
      field: 'modality',
      header: 'Modalidade',
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
    },
    {
      field: 'updateDate',
      header: 'Última Atualização',
      sortable: true,
      body(data: IServiceOrder, options) {
        return <span className='flex flex-col gap-2'>
          <span className='font-semibold'>{data.updateDate}</span>
          <span className='font-normal text-gray-300'>{data.updateBy}</span>
        </span>
      },
    },
    {
      field: 'correspondent',
      header: 'Correspondente',
      sortable: true,
    },
    {
      header: '',
      body: handleButtonsTable,
    },
    // {
    //   header: 'Ações',
    //   body: (row: IServiceOrder) => <Actions id={row.id} title={row.client} />
    // },
  ], [handleButtonsTable]);

  const handleOnRowClick = useCallback(
    (e: DataTableRowClickEvent) => {
      const serviceOrder = e.data as IServiceOrder;
      if (serviceOrder.status_id === 1 && serviceOrder.finality_id <= 2) {
        history.push(`/monte-sua-casa/${parseInt(serviceOrder.osNumber, 10)}`);
      } else {
        history.push(`/dashboard/${parseInt(serviceOrder.osNumber, 10)}`);
      }
    },
    [history]
  );

  return (
    <DataTable
      data={ossBuilder}
      columns={columns}
      loading={loadingOss}
      onRowClick={handleOnRowClick}
    />
  );
};

export default TableOS;
