import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "~/services/api";

interface IParams {
  osId: string;
}

export function ProofPayment() {

    const [onUpload, setOnUpload] = useState<boolean>(false)

    const params = useParams<IParams>();

    async function handleGetFileRawData(event: ChangeEvent) {
        try {
            setOnUpload(true);

            if(!event) throw new Error('Não é possivel continuar');

            const FILE_RAW_DATA = (event.target as HTMLInputElement).files?.[0] || null;

            if(!FILE_RAW_DATA) throw new  Error('Nenhum arquivo foi encontrado');

            const FILE_DATA_PARSED = new Blob([FILE_RAW_DATA]);

            if(!FILE_DATA_PARSED) throw new Error('O arquivo não pode ser enviado');

            const FORM = new FormData();

            FORM.set('tpArquivo_id', '20');
            FORM.set('file', FILE_DATA_PARSED);
            FORM.append('documento_extra', '0');
            FORM.append('precisa_validacao', '0');
            
            const response = await api.post(`builders/service-orders/${params?.osId || -1}/files`,
                FORM,
                {
                    onUploadProgress(progressEvent) {
                        Math.round((progressEvent.loaded * 100) / progressEvent.total) === 100 && setOnUpload(false)
                    },
                }
            )

            if(!response.data?.file?.id) throw new Error('');

            Swal.fire({
                icon: 'success',
                text: "Seu comprovante foi enviado com sucesso"
            });

        } catch (error: any) {            
            Swal.fire({
                icon: 'warning',
                text: error?.message || 'Houve um erro ao enviar o comprovante'
            })
        }
        finally {
            setOnUpload(false)
        }
    }

    return (
        <span className='w-full h-auto p-8 flex flex-col gap-8 rounded-xl bg-gray-100 my-8 border-[1px] border-gray-200'>
            <hgroup>
                <h2 className='font-semibold text-lg'>Comprovante de Pagamento</h2>
                <h3 className='font-normal text-md mt-2'>Faça o upload do comprovante de pagamento (Análise e defesa de proposta de crédito)</h3>
            </hgroup>
            
            <input type="file" name="proof-payment-kiwifi" id="proof-payment-kiwifi"  className="hidden" onChange={handleGetFileRawData} accept=""/>

            <label
                htmlFor="proof-payment-kiwifi"
                role="button" 
                aria-disabled={onUpload}
                data-disabled={onUpload}
                className="
                    w-full
                    md:w-1/2
                    h-12
                    flex flex-row 
                    flex-nowrap items-center 
                    justify-center gap-3 
                    rounded-[25px]
                    bg-[#39a887]
                    hover:bg-[#2c8168]
                    hover:active:bg-[#226450]
                    active:bg-[#2c8168]
                    active:scale-105
                    transition-all
                    duration-[0.37s]
                    text-white
                    data-[disabled=true]:cursor-not-allowed
                    data-[disabled=true]:opacity-50
                "
            >
                {
                    onUpload && 'Enviando...'
                }
                {
                    !onUpload && 'Enviar'
                }                
            </label>
        </span>
    )
}