import { InputNumber } from "primereact/inputnumber";
import { Controller } from "react-hook-form";

// Types
import { ICommonProps } from "../types";

export function PropertyBalanceDue({control,formState,setValue}: ICommonProps<any, any, any,any>) {
    return (<span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-0 border-b-[1px] border-gray-300">
            <label htmlFor="">Saldo devedor do imóvel</label>
            <span data-haserrors={formState?.errors?.property_balance_due?.message && true}
                className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.property_balance_due?.message}</span>
            <Controller
                control={control}
                name="property_balance_due"
                render={({
                    field,
                }) => (
                    <InputNumber 
                        name={field.name}
                        mode="currency" aria-autocomplete="none"
                        currency="BRL"
                        placeholder="R$ 0,00"
                        className="w-full !bg-transparent !border-none  !rounded-none"
                        inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                        onChange={d => setValue('property_balance_due', d.value?.toFixed(2) || '0')}
                    />
                )}
            />
        </span>
    )
}