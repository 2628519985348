import { Controller } from "react-hook-form";

// Types
import { InputText } from "primereact/inputtext";
import { ICommonProps } from "../types";

export function CityOfTerrain({control,formState,setValue}: ICommonProps<any, any, any,any>) {
    return ( <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
        <label htmlFor=""> Cidade do terreno</label>
        <span data-haserrors={formState?.errors?.city_of_terrain?.message && true}
            className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.city_of_terrain?.message}</span>
        <Controller
            control={control}
            name='city_of_terrain'
            render={({
                field,
            }) => (
                <InputText
                    {...field}
                    placeholder="Digite o nome da cidade"
                    className="w-full !bg-transparent !border-none  !rounded-none"
                />
            )}
        />
    </span>
    )
}