import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

// Services
import api from "~/services/api";

// Types
import { ICommonProps } from "../types";
import { TDropDownItems, TProperty } from "./type";

export function Properties({control,formState,setValue,watch}: ICommonProps<any, any, any,any>) {

    const [selectedProperty, setSelectedProperty] = useState<number | null>(null);
    const [properties, setProperties] = useState<TDropDownItems>([]);

    const d = watch!('property_type_id')
    console.log(d)
    async function getAllProperties() {
        try {
            const response = await api.get<TProperty[]>('builders/complementary-data/property-type')

            if(!response) throw new Error('');
                
            const PROPERTIES_ITEMS_MOUNTED = response.data.map((d) => ({
                name: d.type_property,
                value: (d?.id || -1) as unknown as number
            }))

            setProperties(PROPERTIES_ITEMS_MOUNTED)
        } catch (error) {
            // do anything
        }
    }

    useEffect(() => {
        if(!selectedProperty) return;

        console.table({formState,selectedProperty})
        setValue('property_type_id', selectedProperty);
    },[selectedProperty])
    
    useEffect(() => {
        getAllProperties();
    },[])

    return (<span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50% flex flex-col gap-2">
            <label htmlFor="">Tipo do imóvel</label>
            <span data-haserrors={formState?.errors?.property_type_id?.message && true}
                className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.property_type_id?.message}</span>
            <Controller
                control={control}
                name="property_type_id"
                render={({
                    field,
                }) => (
                    <>
                       <Dropdown 
                            value={selectedProperty} 
                            onChange={(e) => setSelectedProperty(e.value)} 
                            options={properties} 
                            optionLabel="name" 
                            placeholder="Selecione o tipo de propriedade" 
                            className="w-full md:w-14rem" 
                        />
                    </>
                )}
            />
        </span>
    )
}