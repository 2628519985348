import { AddressTerrain } from "./Partials/AddressTerrain";
import { AmountBuildEstimated } from "./Partials/AmountBuildEstimated";
import { CityOfTerrain } from "./Partials/CityOfTerrain";
import { FormalMonthlyIncome } from "./Partials/FormalMonthlyIncome";
import { LandOutstandingBalance } from "./Partials/LandOutstandingBalance";
import { NameOfBuild } from "./Partials/NameOfBuild";
import { OwnsAnotherProperty } from "./Partials/OwnsAnotherProperty";
import { Properties } from "./Partials/Properties";
import { PropertyBalanceDue } from "./Partials/PropertyBalanceDue";
import { PropertyValue } from "./Partials/PropertyValue";
import { PropertyValueEstimated } from "./Partials/PropertyValueEstimated";
import { TerrainAmount } from "./Partials/TerrainAmount";
import { UnformalMonthlyIncome } from "./Partials/UnformalMonthlyIncome";

// Types
import type { ICommonProps } from "./Partials/types";

export const Common = {
    TerrainAmount: (args: ICommonProps<any,any,any, any>) => <TerrainAmount {...args} />,
    LandOutstandingBalance: (args: ICommonProps<any,any,any, any>) => <LandOutstandingBalance {...args} />,
    AmountBuildEstimated: (args: ICommonProps<any,any,any, any>) => <AmountBuildEstimated {...args} />,
    PropertyValueEstimated: (args: ICommonProps<any,any,any, any>) => <PropertyValueEstimated {...args} />,
    FormalMonthlyIncome: (args: ICommonProps<any,any,any, any>) => <FormalMonthlyIncome {...args} />,
    UnformalMonthlyIncome: (args: ICommonProps<any,any,any, any>) => <UnformalMonthlyIncome {...args} />,
    CityOfTerrain: (args: ICommonProps<any,any,any, any>) => <CityOfTerrain {...args} />,
    NameOfBuild: (args: ICommonProps<any,any,any, any>) => <NameOfBuild {...args} />,
    AddressTerrain: (args: ICommonProps<any,any,any, any>) => <AddressTerrain {...args} />,
    PropertyBalanceDue: (args: ICommonProps<any,any,any, any>) => <PropertyBalanceDue {...args} />,
    Properties: (args: ICommonProps<any,any,any, any>) => <Properties {...args} />,
    PropertValue: (args: ICommonProps<any,any,any, any>) => <PropertyValue {...args} />,
    OwnsAnotherProperty: (args: ICommonProps<any,any,any, any>) => <OwnsAnotherProperty {...args} />,
}