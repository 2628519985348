import useFormIntegration from "kemis-library/hooks/form";
import { InputNumber } from "primereact/inputnumber";
import { Controller } from 'react-hook-form';
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

// Types
import { IACProps } from "./type";

// Schemas
import { SCFormAC } from "../../schemas/index";

// Partialss
import { Common } from "../../Common";

export function AC(props: IACProps) {
    const { osID } = useParams() as any
    const { register, handleSubmit, formState, control, getValues, setValue, reset,watch } = useFormIntegration<Zod.infer<typeof SCFormAC>>(props.defaultData || {}, SCFormAC);

    async function onSubmit(data: Partial<Zod.infer<typeof SCFormAC>>) {
        try {
            // console.table(data)

            // if (!props?.id) throw new Error("The OS ID is not available");

            // const response = await api.post(`builders/${props?.id}/complementary-data`, data)

            // if (!response || response.status !== 201) throw new Error("Não foi possivel concluir o proceso");

            if(props.amortization && parseInt(data.terrain_amount as unknown as string,10) < 600000.00) {
                throw new Error('Informe um valor de terreno igual ou acima de R$ 600.000,00')
            }

            if (!props.allowFlowCB || typeof props.allowFlowCB !== 'function') throw new Error();

            props.allowFlowCB(data)
        } catch (error: any) {
            Swal.fire({
                icon: 'warning',
                text: error?.message || 'Houve um problema com o processo'
            })
        }
    }

    return <>
        <form
            onSubmit={handleSubmit(onSubmit, (r) => console.table(r))}
            // onSubmit={onSubmite}
            name="form-acquisition-build"
            id="form-acquisition-build"
            autoComplete="off"
            className="w-full h-auto flex max-sm:flex-col flex-row flex-wrap gap-8"
        >
            <span className="w-full h-auto flex pb-3 border-b-[2px] borde-gray-100">
                <h3 className="font-semibold text-xl select-none cursor-default">
                    Questionário para Aquisição + construção
                </h3>
            </span>

            <Common.TerrainAmount control={control} formState={formState} setValue={setValue}/>
            <Common.LandOutstandingBalance control={control} formState={formState} setValue={setValue}/>
            <Common.AmountBuildEstimated control={control} formState={formState} setValue={setValue}/>
            <Common.PropertyValueEstimated control={control} formState={formState} setValue={setValue}/>

            <Common.PropertValue control={control} formState={formState} setValue={setValue}/>
            <Common.PropertyBalanceDue control={control} formState={formState} setValue={setValue}/>
            <Common.Properties control={control} formState={formState} setValue={setValue} watch={watch}/>
            <Common.OwnsAnotherProperty control={control} formState={formState} setValue={setValue} watch={watch}/>
            
            <span className="md:flex-1 max-sm:w-full md:min-w-[48%] md:max-w-[50%] flex flex-col gap-2 border-b-[1px] border-gray-300">
                <label htmlFor=""> Quanto ainda possui em recursos próprios ?</label>
                <span data-haserrors={formState?.errors?.how_much_resources?.message && true}
                    className="hidden data-[haserrors=true]:flex text-red-400 text-sm">{formState?.errors?.how_much_resources?.message}</span>
                <Controller
                    control={control}
                    name='how_much_resources'
                    render={({
                        field,
                    }) => (
                        // @ts-ignore
                        <InputNumber
                            name={field.name}
                            mode="currency" aria-autocomplete="none"
                            currency="BRL"
                            placeholder="R$ 0,00"
                            className="w-full !bg-transparent !border-none  !rounded-none"
                            inputClassName="w-full !bg-transparent !border-none  !rounded-none"
                            onChange={d => setValue('how_much_resources', d.value?.toFixed(2) || '0')}
                        />
                    )}
                />
            </span>
            
            <Common.FormalMonthlyIncome control={control} formState={formState} setValue={setValue}/>
            <Common.UnformalMonthlyIncome control={control} formState={formState} setValue={setValue}/>
            <Common.CityOfTerrain control={control} formState={formState} setValue={setValue}/>
            <Common.NameOfBuild control={control} formState={formState} setValue={setValue}/>
            <Common.AddressTerrain control={control} formState={formState} setValue={setValue}/>
        </form>
        <button type="submit"
            form="form-acquisition-build"
            className="max-sm:w-full 
                w-64 px-4 py-2
                rounded-lg
                text-white font-semibold 
                bg-[#39a887] hover:bg-[#2c8168] hover:active:bg-[#236652]
                transition-colors
                duration-[0.15s]
                flex flex-row items-center justify-center
                mt-12
            "
        >
            continuar
        </button>
    </>
}